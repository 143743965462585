
import { defineComponent } from '@nuxtjs/composition-api';
import Icon from '@/components/icon/Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    componentTag: {
      type: String,
      default: 'li',
    },
    iconName: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      default: '',
    },
  },
});
