
import { getCurrentInstance } from 'vue';
import { vueVimeoPlayer as VueVimeoPlayer } from 'vue-vimeo-player';
import { defineComponent, nextTick, reactive } from '@nuxtjs/composition-api';
import Icon from '@/components/icon/Icon.vue';
const DEFAULT_FALLBACK_IMAGE = () =>
  require(`@/assets/images/study-online-info.background.webp`);

export default defineComponent({
  components: {
    VueVimeoPlayer,
    Icon,
  },
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    placeholderImage: {
      type: String,
      default: '',
    },
    playButtonText: {
      type: String,
      default: '',
    },
    playButtonArialLabel: {
      type: String,
      default: '',
    },
  },
  setup: (props) => {
    const instance = getCurrentInstance();
    const { $customEvents } = instance!.proxy;

    const state = reactive({
      shouldDisplayPlayer: false,
    });

    const videoReadyHandler = () => {
      document.dispatchEvent($customEvents?.onVimeoReady());
    };

    const videoEndedHandler = () => {
      state.shouldDisplayPlayer = false;
    };

    const placeholderBackgroundImageHandler = () => {
      const { placeholderImage, videoUrl } = props;
      if (videoUrl && !placeholderImage) {
        return DEFAULT_FALLBACK_IMAGE();
      }
      try {
        return placeholderImage?.startsWith('https://')
          ? placeholderImage
          : require(`@/assets/images/${props.placeholderImage}`);
      } catch (_) {
        return DEFAULT_FALLBACK_IMAGE();
      }
    };

    const playButtonClickHandler = async () => {
      state.shouldDisplayPlayer = true;
      await nextTick();
      // @ts-ignore
      instance!.proxy.$refs.player.play();
    };

    const videoLoaded = async () => {
      await nextTick();
      const videoWrapper = document.getElementById('video-wrapper');
      const iframe = videoWrapper?.querySelector('iframe');
      iframe?.focus();
    };

    return {
      state,
      playButtonClickHandler,
      videoEndedHandler,
      videoReadyHandler,
      placeholderBackgroundImageHandler,
      videoLoaded,
    };
  },
});
