
import { defineComponent } from '@nuxtjs/composition-api';
import BaseButton from '@/components/buttons/base-button/BaseButton.vue';
import Icon from '@/components/icon/Icon.vue';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';

export default defineComponent({
  components: {
    SectionContentContainer,
    BaseButton,
    Icon,
  },
});
