
import { getCurrentInstance } from 'vue';
import {
  defineComponent,
  onMounted,
  onUnmounted,
} from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    anchorTargetId: {
      type: String,
      required: true,
    },
    scrollBehavior: {
      type: String,
      default: 'smooth',
    },
  },
  setup: (props) => {
    const instance = getCurrentInstance();
    const scrollToHashHandler = (event?: CustomEvent) => {
      const anchorTargetElement = instance!.proxy.$refs
        ?.anchorTargetElement as HTMLElement;
      const anchorId = `#${anchorTargetElement?.id}`;
      const routeHash = instance!.proxy.$route.hash;
      const isValidHash = event?.detail
        ? event.detail.includes(anchorId)
        : routeHash.includes(anchorId);

      if (isValidHash) {
        anchorTargetElement?.scrollIntoView({
          behavior: props.scrollBehavior as ScrollBehavior,
        });
      }
    };

    onMounted(() => {
      scrollToHashHandler();

      document.addEventListener(
        'onScrollToHash',
        (event) => scrollToHashHandler(event as CustomEvent),
        false
      );
    });

    onUnmounted(() => {
      document.removeEventListener(
        'onScrollToHash',
        (event) => scrollToHashHandler(event as CustomEvent),
        false
      );
    });
  },
});
