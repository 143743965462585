
import { defineComponent, ref } from '@nuxtjs/composition-api';
import Masthead from '@/ols-pages/homepage/masthead/Masthead.vue';
import FeaturedCourses from '@/ols-pages/homepage/featured-courses/FeaturedCourses.vue';
import WhyLearn from '@/ols-pages/homepage/why-learn/WhyLearn.vue';
import AboutCaoInformation from '@/ols-pages/homepage/about-cao-information/AboutCaoInformation.vue';
import MarketingSignUpForm from '@/components/forms/marketing-signup-form/MarketingSignUpForm.vue';
import VideoBlock from '@/components/video-block/VideoBlock.vue';
import MainContent from '@/components/main-content/MainContent.vue';
import useGetCommonContent from '@/composables/commonContent/useGetCommonContent';
import Testimonials from '@/ols-pages/course/testimonials/Testimonials.vue';

export default defineComponent({
  components: {
    Masthead,
    WhyLearn,
    FeaturedCourses,
    AboutCaoInformation,
    MarketingSignUpForm,
    VideoBlock,
    MainContent,
    Testimonials,
  },
  setup: (_, ctx) => {
    const {
      featuredCourses,
      featuredTestimonials,
      commonContentFetchingErrorOccured,
      isFetchingCommonContent,
    } = useGetCommonContent(ctx);

    const featuredTestimonialsContent = ref({
      testimonials: featuredTestimonials,
      cardsBorderColor: '#6BBBAE',
    });

    return {
      featuredCourses,
      featuredTestimonials,
      featuredTestimonialsContent,
      commonContentFetchingErrorOccured,
      isFetchingCommonContent,
    };
  },
  head: {
    title: 'Homepage',
    meta: [
      {
        hid: 'og:title',
        property: 'og:title',
        content: `Homepage | Cambridge Advance Online`,
      },
    ],
  },
});
