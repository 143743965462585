var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.featuredCoursesFetchingErrorOccured ||
    (_vm.featuredCourses && _vm.featuredCourses.length)
  )?_c('section',{staticClass:"section-spacing bg-page-grey",attrs:{"data-testid":_vm.id}},[_c('ScrollAnchorTarget',{attrs:{"anchor-target-id":_vm.anchorTargetId}}),_vm._v(" "),_c('SectionContentContainer',{staticClass:"flex flex-col mx-5"},[_c('h2',{staticClass:"text-center",class:[
        _vm.headerClasses,
        { 'mb-60px lg:mb-80px': !_vm.subtitle, 'mb-20px': _vm.subtitle },
      ]},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),(_vm.subtitle)?_c('h3',{staticClass:"mb-50px text-center text-m4 max-w-sm m-auto px-2 lg:mb-80px"},[_vm._v("\n      "+_vm._s(_vm.subtitle)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.isFetchingFeaturedCourses)?_c('Loader',{staticClass:"mb-60px lg:mb-80px",attrs:{"text-label":"Loading featured courses"}}):(_vm.featuredCoursesFetchingErrorOccured)?_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-error mb-60px lg:mb-80px"},[_vm._v("\n        Something went wrong loading featured courses, please try again later\n        or contact our technical support.\n      ")])]):_c('FeturedCoursesList',{attrs:{"featured-courses":_vm.featuredCourses}}),_vm._v(" "),_c('BaseButton',{staticClass:"w-64 self-center",attrs:{"button-tag":"ols-link","to":"/courses","data-testid":"view-all-courses"}},[_vm._v("\n      View all courses\n    ")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }