import { render, staticRenderFns } from "./BaseImageCard.vue?vue&type=template&id=35397cb8&"
import script from "./BaseImageCard.vue?vue&type=script&lang=ts&"
export * from "./BaseImageCard.vue?vue&type=script&lang=ts&"
import style0 from "./BaseImageCard.vue?vue&type=style&index=0&id=35397cb8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OlsLink: require('/usr/src/nuxt-app/src/components/ols-link/OlsLink.vue').default})
