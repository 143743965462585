var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-black bg-cover bg-center relative videoPlaceholder",class:[
    {
      'darken-overlay': !_vm.state.shouldDisplayPlayer,
    },
  ],style:({
    backgroundImage: `url(${_vm.placeholderBackgroundImageHandler()})`,
  }),attrs:{"id":"video-wrapper"}},[(!_vm.state.shouldDisplayPlayer)?_c('div',{staticClass:"centered-play-button absolute flex flex-col justify-center items-center z-10"},[_c('button',{attrs:{"aria-label":_vm.playButtonArialLabel},on:{"click":_vm.playButtonClickHandler}},[_c('Icon',{staticClass:"w-16 h-16 md:h-32 md:w-32 mb-0 md:mb-3",attrs:{"name":"video-play"}})],1),_vm._v(" "),(_vm.playButtonText)?_c('p',{staticClass:"text-white font-normal text-sm md:text-base text-center mt-10px"},[_vm._v("\n      "+_vm._s(_vm.playButtonText)+"\n    ")]):_vm._e()]):_c('client-only',[_c('VueVimeoPlayer',{ref:"player",class:{ invisible: !_vm.state.shouldDisplayPlayer },attrs:{"video-id":null,"video-url":_vm.videoUrl,"options":{ responsive: true }},on:{"ended":_vm.videoEndedHandler,"ready":_vm.videoReadyHandler,"play":_vm.videoLoaded}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }