
import { defineComponent } from '@nuxtjs/composition-api';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import VimeoPlayer from '@/components/vimeo-player/VimeoPlayer.vue';

export default defineComponent({
  components: {
    SectionContentContainer,
    VimeoPlayer,
  },
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    placeholderImage: {
      type: String,
      default: '',
    },
    playButtonText: {
      type: String,
      default: '',
    },
    playButtonAriaLabel: {
      type: String,
      required: true,
    },
  },
});
