
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import dayjs from 'dayjs';
import BaseImageCard from '@/components/cards/base-with-image-card/BaseImageCard.vue';
import {
  truncate,
  getThemeSetForCategory,
  formatCardDate,
} from '@/shared/utils';
import { CourseCategory } from '@/shared/types/course/course.types';

export default defineComponent({
  components: {
    BaseImageCard,
  },
  props: {
    componentTag: {
      type: String,
      default: 'div',
    },
    title: {
      type: String,
      required: true,
    },
    category: {
      type: Object as PropType<CourseCategory>,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    date: {
      type: String,
      default: undefined,
    },
    slug: {
      type: String,
      default: '#',
    },
    courseDuration: {
      type: String,
      default: '',
    },
  },
  setup: (props) => {
    const cardTitle = truncate(props.title, 100);
    const cardDescription = truncate(props.description, 150);
    const cardDate = formatCardDate(props.date);

    const { borderColor: cardBorderColor, bgImage: cardImage } =
      getThemeSetForCategory(props.category);

    const durationInWeeks = props.courseDuration
      ? `${dayjs.duration(props.courseDuration).asWeeks()} weeks`
      : 'to be confirmed';

    return {
      cardTitle,
      cardDescription,
      cardDate,
      cardBorderColor,
      durationInWeeks,
      cardImage,
    };
  },
});
