
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    componentTag: {
      type: String,
      default: 'div',
    },
    cardId: {
      type: String,
      required: true,
    },
    cardTitle: {
      type: String,
      required: true,
    },
    cardType: {
      type: String,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    cardLink: {
      type: String,
      default: '#',
    },
    cardBorderColor: {
      type: String,
      default: 'black',
    },
    cardImage: {
      type: String,
      default: '',
    },
    imageClass: {
      type: String,
      default: '',
    },
    cardComponentClass: {
      type: String,
      default: '',
    },
    isContentBesideImage: {
      type: Boolean,
      default: false,
    },
  },
});
