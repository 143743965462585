
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { FeaturedCourse } from '../featuredCourses.types';
import CourseCard from '@/components/cards/course-card/CourseCard.vue';
export default defineComponent({
  components: {
    CourseCard,
  },
  props: {
    featuredCourses: {
      type: Array as PropType<FeaturedCourse[]>,
      default: () => [],
    },
  },
});
