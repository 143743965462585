import { getCurrentInstance } from 'vue';
import { ref, SetupContext, useFetch } from '@nuxtjs/composition-api';
import { Course } from '@/shared/types';
import getCommonContent from '@/shared/graphql/commonContent/GetCommonContent.gql';
import {
  GetCommonContentQuery,
  GetCommonContentQueryVariables,
} from '@/shared/graphql/schema.types';
import { CourseTestimonial } from '~/shared/types/course/course.types';

export default function useGetCommonContent(ctx: SetupContext) {
  const instance = getCurrentInstance();
  const featuredCourses = ref<Partial<Course>[]>();
  const featuredTestimonials = ref<Partial<CourseTestimonial>[]>();
  const commonContentFetchingErrorOccured = ref<Boolean>(false);
  const isFetchingCommonContent = ref<Boolean>(true);

  const fetchCommonContent = async () => {
    try {
      const { data } = await instance!.proxy.$gqlFetch<
        GetCommonContentQuery,
        GetCommonContentQueryVariables
      >(getCommonContent);

      featuredCourses.value = data.commonContent
        ?.featuredCourses as Partial<Course>[];
      featuredTestimonials.value = data.commonContent
        ?.featuredTestimonials as Partial<CourseTestimonial>[];
    } catch (e) {
      commonContentFetchingErrorOccured.value = true;
    }
    isFetchingCommonContent.value = false;
  };

  useFetch(fetchCommonContent);

  return {
    featuredCourses,
    featuredTestimonials,
    commonContentFetchingErrorOccured,
    isFetchingCommonContent,
    fetchCommonContent,
  };
}
