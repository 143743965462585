
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import FeturedCoursesList from './components/FeaturedCoursesList.vue';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import BaseButton from '@/components/buttons/base-button/BaseButton.vue';
import Loader from '@/components/loader/Loader.vue';
import ScrollAnchorTarget from '@/components/scroll-anchor-target/ScrollAnchorTarget.vue';
import { AnchorHashEnum, Course } from '@/shared/types';

export default defineComponent({
  components: {
    SectionContentContainer,
    BaseButton,
    ScrollAnchorTarget,
    FeturedCoursesList,
    Loader,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      required: false,
      type: String,
      default: 'Featured Courses',
    },
    subtitle: {
      required: false,
      type: String,
      default: '',
    },
    headerClasses: {
      type: String,
      default: 'base-section-heading',
    },
    featuredCourses: {
      type: Array as PropType<Course[]>,
      default: () => [],
    },
    isFetchingFeaturedCourses: {
      required: true,
      type: Boolean,
    },
    featuredCoursesFetchingErrorOccured: {
      required: true,
      type: Boolean,
    },
  },
  setup: () => {
    return {
      anchorTargetId: AnchorHashEnum.FEATURED_COURSES_SECTION.slice(1),
    };
  },
});
