import { gql } from '@/shared/utils';

export default gql`
  query GetCommonContent {
    commonContent {
      featuredCourses {
        _id
        category {
          name
          backgroundImage
          themeColorHex
          eduframeCategoryId
        }
        slug
        duration
        title
        shortDescription
        nextAvailableDate
      }
      featuredTestimonials {
        learnerName
        learnerName
        learnerJobTitle
        learnerCompanyName
        profilePictureUrl
        testimonial
        courseTakenSlug
        courseTakenName
      }
    }
  }
`;
