
import { defineComponent } from '@nuxtjs/composition-api';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import InfoImageCard from '@/components/cards/info-image-card/InfoImageCard.vue';

export default defineComponent({
  components: {
    InfoImageCard,
    SectionContentContainer,
  },
});
