
import { defineComponent } from '@nuxtjs/composition-api';
import SectionContentContainer from '@/components/section-content-container/SectionContentContainer.vue';
import ShortInfoItem from '@/components/short-info-item/ShortInfoItem.vue';

export default defineComponent({
  components: {
    ShortInfoItem,
    SectionContentContainer,
  },
});
