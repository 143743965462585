var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.componentTag,{tag:"component",staticClass:"border border-transparent hover:border-black transition ease-in-out duration-200",class:`${_vm.cardType}-card ${_vm.cardComponentClass}`},[_c('ols-link',{staticClass:"shadow-card hover:shadow-card-hovered h-inherit flex flex-col cursor-pointer bg-white no-underline text-dark-grey border-b visited:text-dark-grey",class:{
      [`${_vm.cardType}-card__link`]: true,
      'sm-same-height md:grid md:grid-cols-3': _vm.isContentBesideImage,
    },style:({
      borderBottomColor: _vm.cardBorderColor,
      outlineOffset: '4px',
    }),attrs:{"to":_vm.cardLink,"data-testid":`view-${_vm.cardType}-${_vm.cardId}`,"aria-label":`View ${_vm.cardType} ${_vm.cardTitle}`}},[_c('div',{staticClass:"relative",class:{ 'md:col-span-2': _vm.isContentBesideImage }},[_c('nuxt-img',{staticClass:"w-full object-cover object-center",class:_vm.imageClass,attrs:{"alt":"","loading":"lazy","format":"webp","src":`${_vm.cardImage}`,"quality":"70"}}),_vm._v(" "),_c('span',{staticClass:"font-medium absolute",class:`${_vm.cardType}-card__category`},[_vm._v("\n        "+_vm._s(_vm.categoryName)+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"p-20px flex-1 flex flex-col justify-between",class:{
        'md:px-30px md:py-40px': _vm.isContentBesideImage,
        'border-l border-r border-black border-opacity-60 hover:border-opacity-100':
          !_vm.isContentBesideImage,
      }},[_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }